const GlobeEmoji = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" height="36px">
    <circle fill="#88C9F9" cx="18" cy="18" r="18" />
    <path
      fill="#5C913B"
      d="M30.13 23.748c-1.017-.39-.836-.248-1.463-1.581-.627-1.332-1.007-1.761-2.417-1.917-1.411-.155-1.358-.045-1.75-.75-.392-.706-1.982.191-3 .584-1.021.392-.769 1.246-1.083 1.166-.315-.078-1.107-.281-1.893-.907-.784-.628.583-1.118.976-1.509.393-.393-.74-.179-1.133-.416-.393-.234-1.096.471-1.723-.158-.628-.627-.235-1.568-.313-2.352-.079-.785 1.018-.941 1.724-1.098.705-.156 1.253.001 1.253.941 0 .941.863 1.255.628.156s.235-1.411.55-1.96c.313-.549.862-1.334 1.567-2.51.706-1.176 1.177-.628 2.039-.544.863.085 1.098-.083 1.725-.004.625.079 0-.784.236-1.646.234-.862 1.097-.706 1.961-.47.862.235-.864-1.099-1.177-1.804-.315-.706-1.177 0-1.724-1.176-.553-1.176-1.324-1.937-2.03-2.042-.706-.106.225 1.494-.324 2.356-.551.862-1.395.727-2.26.727-.861 0-1.661-.727-1.033-1.825.626-1.097 1.823-.984 2.45-1.925.628-.94-2.23-.854-3.25-1.167-1.02-.314-2.244-.311-3.263.473-1.02.783-3.201 1.028-4.064 1.341-.862.313-1.017-.52-1.173-1.148-.07-.279-.388-.367-.836-.357C7.11 3.45 5.187 5.138 3.664 7.147c.237.807 1.385-.189 1.61-.414.235-.235 1.411.079 1.567.941.157.862.079 1.805-.234 2.589-.314.783.784 1.881 1.411 2.979.628 1.099 1.333 1.098 1.647 1.098.313 0 2.117.706 2.038 1.568-.078.863 1.647 1.646 2.353 2.195.706.551 1.152 1.092 2.068 1.25.917.158 1.989 1.657 2.459 1.815.361.12.972.697 1.341.876-.485.614-1.424 1.875-1.424 2.456 0 .786 1.025 2.021 1.417 2.334.391.312 1.517.55 1.75 1.333.236.784-.164 3.423-.868 4.128-.708.705-.965 2.372-.965 2.372s.249.541 1.083 0C21.839 34.07 23.383 32.37 24 32c1.176-.707 2.319-1.565 3.417-2.583 1.098-1.019.7-1.733 1.25-2.75.549-1.019 1.259-.973 2.199-1.833.942-.864.284-.693-.736-1.086z"
    />
  </svg>
);

export default GlobeEmoji;
