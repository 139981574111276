import { useEffect } from 'react';
import { useFetcher, Link } from '@remix-run/react';
import type { loader as getMembersLoader } from '~/routes/project.$id.actions.members';
import ProjectCardListing from '~/components/shared/ProjectCardListing';
import type { Project } from '~/models/project.server';
import type { User } from '~/models/user.server';
import UsersAvatars from '~/components/shared/UsersAvatars';
import GlitchEmoji from '~/components/shared/GlitchEmoji';
import GlobeEmoji from '~/components/shared/GlobeEmoji';
import ProjectCardActions from '~/components/shared/ProjectCardActions';
import './project-card.css';
import { IconLock } from '@fastly/beacon-icons';
import type { Playlist } from '~/models/playlist.server';

export type ProjectCardProps = {
  description?: string;
  featured?: boolean;
  href: string;
  id?: Project['id'];
  imgSrc: string;
  isPrivate?: boolean;
  isUser?: boolean;
  pinned?: boolean;
  playlistId?: Playlist['id'];
  remixHref?: string;
  showDescription?: boolean;
  size?: 'listing' | 'sm' | 'md' | 'lg' | 'xl';
  title?: string;
  userId?: User['id'];
};

const ProjectCard = ({
  description,
  featured,
  href,
  id,
  imgSrc,
  isPrivate,
  isUser,
  pinned,
  playlistId,
  remixHref,
  showDescription = false,
  size = 'md',
  title,
  userId,
}: ProjectCardProps) => {
  const fetcher = useFetcher<typeof getMembersLoader>();
  const limit = size === 'sm' ? 2 : 6;

  useEffect(() => {
    if (
      size !== 'listing' &&
      id &&
      remixHref &&
      fetcher.state === 'idle' &&
      !fetcher.data
    ) {
      fetcher.load(`/project/${id}/actions/members?limit=${limit}`);
    }
  }, [fetcher.state, fetcher.data, size, fetcher, id, limit, remixHref]);

  if (size === 'listing') {
    return (
      <ProjectCardListing
        featured={featured}
        description={description}
        href={href}
        id={id}
        imgSrc={imgSrc}
        isPrivate={isPrivate}
        isUser={isUser}
        pinned={pinned}
        showDescription={showDescription}
        title={title}
        userId={userId}
      />
    );
  }

  return (
    <div
      className={`project-card project-card-${size}`}
      data-testid="project-card-container"
    >
      {isUser && (
        <ProjectCardActions
          userId={userId}
          playlistId={playlistId}
          projectId={id}
          pinned={pinned}
          featured={featured}
        />
      )}
      <div className="project-card-avatars">
        <img className="project-card-img" src={imgSrc} alt="" />
        {id && (
          <UsersAvatars
            users={fetcher?.data?.members}
            loading={fetcher.state !== 'idle'}
          />
        )}
      </div>
      <div className="project-card-details">
        <div className="project-card-info">
          <Link className="project-card-title" to={href}>
            {isPrivate && <IconLock />}
            <h3 className="project-card-domain">{title}</h3>
          </Link>
          {size !== 'sm' && (
            <p className="project-card-description">{description}</p>
          )}
        </div>
        <div className="project-card-links">
          {/* Hide these buttons until we hook them up */}
          {/* <button className="button-secondary">
            <ArrowUpTrayIcon aria-hidden />
            Share
          </button>
          <button className="button-secondary">
            <HeartIcon aria-hidden />
            Love
          </button> */}
          <Link to={remixHref || href} className="button no-hover-effect">
            {remixHref ? <GlitchEmoji /> : <GlobeEmoji />}
            {remixHref ? 'Remix' : 'Visit'}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
